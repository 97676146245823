import { useState, useEffect, useRef } from 'react';

import {
    ACCEPTED_SONGS,
    SONG_ID_SPECIFIED,
    USER_ID_SPECIFIED,
    LOCAL_API_PATH
} from '../config';

import API from '../API';
import { useAuth } from '../contexts/AuthContext'
export const useMessengerFetch = (currentUserID) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [loadingSideBar, setLoadingSideBar] = useState(false);
    const [messageInput, setMessageInput] = useState({
        song_id: '',
        songwriter_id: '',
        sender_id: currentUserID,
        receiver_id: '',
        message: ''
    });
    const [messages, setMessages] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState({ user_id: '', song_id: '', song: {} });
    const [messageHeaders, setMessageHeaders] = useState({});
    const [songDetails, setSongDetails] = useState(null)
    const [selectedUser, setSelectedUser] = useState('');
    const [error, setError] = useState(false);
    const [userIDs, setUserIDs] = useState([])
    const initial = useRef(true)
    const initialSelectedArtist = useRef(true)
    const resultsPerPage = 5
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(resultsPerPage)

    const fetchMessageHeaders = async () => {
        API.fetchMessagesAdmin(currentUser.accessToken, currentUser.email, page, resultsPerPage, null, null).then((res) => {
            const [mh, total] = res
            setMessageHeaders({ ...mh })
            setTotalResults(total)
        }).catch((e) => { console.log(e) })
    }

    const fetchMessages = async (userID = null, songID = null) => {
        try {
            setError(false);
            setLoading(true);
            const [messagesX, user, song] = await API.fetchMessages(userID, songID, page, resultsPerPage)
            setMessages(messagesX)
            setSongDetails(song)
            setLoading(false)
            setSelectedUser({ ...user })
            const adminId = '1';
            if (adminId === messagesX[0].receiver_id && messagesX[0].is_new === '1') {
                API.updateMessageReadStatus(messagesX[0].song_id, messagesX[0].receiver_id, user.user_id)

                setMessageHeaders(prev => ({
                    ...prev,
                    [user.email]:
                        [...prev[user.email].map(it => it.song_id === messagesX[0].song_id ? { ...it, is_new: "0" } : { ...it })]
                }))
            }
        } catch (error) {
            setError(true);
            //console.log(error)
        }

    }

    useEffect(() => {
        setLoadingSideBar(true)
        fetchMessageHeaders().then(() => {
            setLoadingSideBar(false)
        })

    }, [page])

    const handleChange = (event) => {
        setMessageInput(prev => ({ ...prev, message: event.target.value }))

    }


    const sendMessage = async () => {
        if (messageInput.message.replace(/\s/g, '').length) {
            try {
                setError(false);
                setLoading(true);
                const res = await API.sendMessage(messageInput.receiver_id, messageInput.sender_id, messageInput.songwriter_id, messageInput.song_id, messageInput.message);
                fetchMessages(selectedMessage.user_id, selectedMessage.song_id)
            } catch (error) {
                setError(true);
            }
            setLoading(false);
        }

    };
    const continueKey = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessage().then(() => { setMessageInput((prev) => ({ ...prev, message: "" })) });
        }
    }




    useEffect(() => {
        if (initialSelectedArtist.current) {
            initialSelectedArtist.current = false;
            return;
        }
        //console.log("result is", !Object.values(messageHeaders).filter(it => it.filter(x => (x.song_id === selectedMessage.song_id && x.user_id === selectedMessage.user_id)).length > 0).length > 0)
        if (Object.keys(messageHeaders).length > 0 &&
            !(Object.values(messageHeaders).filter(it => it.filter(x => (x.song_id === selectedMessage.song_id && x.user_id === selectedMessage.user_id)).length > 0)).length > 0) {
            const initial = messageHeaders[(Object.keys(messageHeaders)[0])][0]
            setSelectedMessage({ user_id: initial.user_id, song_id: initial.song_id, song: { ...initial } })
        }


    }, [messageHeaders])

    useEffect(() => {
        if (selectedMessage.user_id !== undefined && selectedMessage.song_id !== undefined) {
            fetchMessages(selectedMessage.user_id, selectedMessage.song_id)
            setMessageInput({ song_id: selectedMessage.song_id, songwriter_id: selectedMessage.user_id, sender_id: currentUserID, receiver_id: currentUserID === '1' ? selectedMessage.user_id : '1' })
        }

    }, [selectedMessage])

    return { resultsPerPage, totalResults, page, setPage, songDetails, messageHeaders, sendMessage, selectedUser, continueKey, loadingSideBar, handleChange, messageInput, setMessageInput, setUserIDs, loading, error, messages, setSelectedMessage, selectedMessage };

}

