import React from 'react'



import {
    Wrapper,
    Process,
    ProcessHeader,
    SubText,
    Song,
    Tempo,
    Genre,
    Spotify,
    Artist,
    Email,
    Accept,
    Reject,
    Status,
    Date,
    GridItem
} from './SongwriterTabHeader.styles'

import clock from '../../../images/clockIcon.svg'

const SongwriterTabHeader = ({ isApproved, type }) => {
    if (isApproved && type === 'songwriters') {
        return (
            <Wrapper
                // gridTemplateAreas={"artist email spotify acceptreject"}
                // gridTemplateCols={'1fr 1fr 60px 110px'}
                gridTemplateAreas={"artist email publisher instagram spotify acceptreject"}
                gridTemplateCols={'1fr 2fr 1fr 80px 60px 110px'}
            >
                <Artist>Songwriter</Artist>
                <GridItem gridArea="publisher">Publisher</GridItem>
                <GridItem justifyContent="center" gridArea="instagram">Instagram</GridItem>
                <Email>Email</Email>
                <Spotify>Spotify</Spotify>
                <Process isApproved={isApproved}>
                    <Reject>
                        Revoke Acceptance
                    </Reject>
                </Process>
            </Wrapper>
        )
    }
    else if (type === 'songwriters') {
        return (
            <Wrapper
                gridTemplateAreas={"spacer artist song tempo genre publisher date instagram spotify acceptreject"}
                gridTemplateCols={'20px 2fr repeat(2,1fr) 120px 1fr 70px 80px 60px min-content'}
            >
                <Song>Song</Song>
                <Tempo>Tempo</Tempo>
                <Genre>Genre</Genre>
                <Artist>Songwriter</Artist>
                <GridItem gridArea="publisher">Publisher</GridItem>
                <GridItem justifyContent="center" gridArea="instagram">Instagram</GridItem>
                <Date>Received</Date>
                <Spotify>Spotify</Spotify>
                <Process>
                    <ProcessHeader>
                        {isApproved ? 'Song' : 'Songwriter'}
                    </ProcessHeader>
                    <SubText>Accept</SubText>
                    <SubText>Reject</SubText>
                </Process>
            </Wrapper>
        )
    }
    else if (isApproved && type === "songs") {

        return (
            <Wrapper
                gridTemplateAreas={"spacer song artist tempo genre status price date spotify"}
                gridTemplateCols={'20px repeat(4,1fr) 250px 100px 60px 60px'}>
                <Song>Song</Song>
                <Tempo>Tempo</Tempo>
                <Genre>Genre</Genre>
                <Artist>Songwriter</Artist>
                <GridItem gridArea="price">Price</GridItem>
                <Date><img src={clock} alt="clockIcon" style={{ width: "15px" }} /></Date>
                <Status>Status</Status>
                <Spotify>Spotify</Spotify>
            </Wrapper>
        )
    }
    else if (!isApproved && type === "songs") {

        return (
            <Wrapper
                gridTemplateAreas={"spacer artist song tempo genre date spotify acceptreject"}
                gridTemplateCols={'20px repeat(5,1fr) 60px min-content'}
            >
                <Song>Song</Song>
                <Tempo>Tempo</Tempo>
                <Genre>Genre</Genre>
                <Artist>Songwriter</Artist>
                <Date>Received</Date>
                <Spotify>Spotify</Spotify>
                <Process>
                    <ProcessHeader>
                        Song
                    </ProcessHeader>
                    <SubText>Accept</SubText>
                    <SubText>Reject</SubText>
                </Process>
            </Wrapper>
        )
    }
    else {
        return <Wrapper></Wrapper>
    }

}

export default SongwriterTabHeader
