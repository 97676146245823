import styled from "styled-components";

export const Wrapper = styled.div`
padding: 10%;
`;

export const QuestionWrapper = styled.div`
width: 100%;
position: relative;
`;

export const SectionWrapper = styled.div`

`;

export const SectionWrapperHeader = styled.div`
border-bottom: 1px solid #ffffff;
text-transform: uppercase;
margin-top: 20px;
align-items: center;
display: flex;
justify-content: space-between;
`;

export const PriceWrapper = styled.div`
width: 300px;
`;

export const AddIconWrapper = styled.div`
    cursor: pointer;
    width: 40px;
    height: 40px;
`;

export const RemoveCollaboratorWrapper = styled.div`
cursor: pointer;
display: flex;
position: relative;
justify-content: center;
visibility: ${props => props.visibility ? props.visibility : 'visible'};
align-items: center;
img{
    height: 40px;
}
`;