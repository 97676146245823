import React, { useEffect, useState, useRef } from 'react'

import { Wrapper, OuterWrapper, Row } from './SongwritersTab.styles'

import Songwriter from '../Songwriter'
import SongwriterTabHeader from '../SongwriterTabHeader'
import SongDetailsModal from '../SongDetailsModal'

const SongwritersTab = ({
    setProcessArtist,
    handleSongAssignment,
    handleArtistAssignment,
    type,
    isApproved,
    songs,
    loading,
    playing,
    nowPlaying,
    handleSongChange,
    songStatusKey,
    onSkip
}) => {
    const [showModal, setShowModal] = useState(false)

    if (loading) {
        return <OuterWrapper></OuterWrapper>
    }
    else if (isApproved && type !== "songs") {
        const resArr = [];
        songs.filter(item => {
            if (resArr.findIndex(x => x.user_id === item.user_id) === -1)
                resArr.push(item);
            return null
        })
        return (
            <OuterWrapper>
                <Wrapper>
                    <SongwriterTabHeader isApproved={isApproved} type={type} songStatusKey={songStatusKey} />
                    {songs.map((artist) => (
                        <Songwriter
                            songStatusKey={songStatusKey}
                            setProcessArtist={setProcessArtist}
                            handleArtistAssignment={handleArtistAssignment}
                            handleSongAssignment={handleSongAssignment}
                            key={artist.id}
                            values={artist}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            playing={playing}
                            handleSongChange={handleSongChange}
                            nowPlaying={nowPlaying}
                            isApproved={isApproved}
                            type={type}
                        />
                    ))}
                </Wrapper>

            </OuterWrapper>
        )
    }
    else if (songs !== null) {
        return (
            <OuterWrapper>
                <Wrapper>
                    <SongwriterTabHeader isApproved={isApproved} type={type} songStatusKey={songStatusKey} />
                    {songs.map((artist) => (
                        <Songwriter
                            songStatusKey={songStatusKey}
                            setProcessArtist={setProcessArtist}
                            handleArtistAssignment={handleArtistAssignment}
                            handleSongAssignment={handleSongAssignment}
                            key={artist.song_id}
                            values={artist}
                            onSkip={onSkip}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            playing={playing}
                            handleSongChange={handleSongChange}
                            nowPlaying={nowPlaying}
                            isApproved={isApproved}
                            type={type}
                        />
                    ))}
                </Wrapper>

            </OuterWrapper>
        )
    }
    else {
        return <OuterWrapper>No new songs</OuterWrapper>
    }

}

export default SongwritersTab
