import React, { useEffect, useRef, useState } from 'react'

import { Wrapper, InputStyled, LabelStyled, InnerWrapper, DollarSign } from './InputField.styles'


const Label = ({ name, marginBottom, label, labelFontSize, labelTextTransform }) => (


    <LabelStyled
        htmlFor={name}
        labelFontSize={labelFontSize}
        labelTextTransform={labelTextTransform}
        marginBottom={marginBottom}
    >{label}</LabelStyled>
)




const InputField = ({ disabled, labelFontSize, labelTextTransform, type, image, onChange, placeholder, value, label, name, marginBottom, onKeyPress, priceOnBlur }) => {
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);
    const handleCursor = (event) => {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            //console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);



        }
    }, [value])

    if (label !== undefined) {

        return (
            <Wrapper>
                <Label htmlFor={name}
                    marginBottom={marginBottom}
                    label={label}
                    labelFontSize={labelFontSize}
                    labelTextTransform={labelTextTransform}
                ></Label>
                <InnerWrapper>
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <InputStyled
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                    />
                </InnerWrapper>
            </Wrapper >

        )
    }
    else {
        return (
            <InnerWrapper>
                {name === 'price' && <DollarSign>$</DollarSign>}
                <InputStyled
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled ? true : false}
                    onChange={handleCursor}
                    name={name}
                    onKeyPress={onKeyPress}
                    ref={inputRef}
                    onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                />
            </InnerWrapper>
        )
    }



}

export default InputField
