import styled from 'styled-components'

export const Wrapper = styled.div`
    border: 1px solid var(--greyBackground);
    width: 100%;
    margin-top: 2px;
    height: 100%;
    margin-bottom: 50px;
   
`;

export const OuterWrapper = styled.div`
`;

export const Row = styled.div``;

