import React from 'react';

import { ButtonStyle } from './Button.styles';

const Button = ({ validContinue, children, position, background, height, width, color, text, border, hoverColor, onClick }) => {
    return (


        <ButtonStyle
            validContinue={validContinue !== undefined ? validContinue : true}
            onClick={onClick}
            background={background} height={height} width={width} color={color} border={border} hoverColor={hoverColor} >
            {text}{children}
        </ButtonStyle>
    )
}
export default Button;