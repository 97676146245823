const VALID_FILE_TYPES = ["audio/mpeg", "audio/wav"];
const LOCAL_API_PATH = 'http://localhost:3000/music-platform/api/sendEmail/index.php';
const MAX_SIZE = 500000000;
const ACCEPTED_SONGS = 1;
const SONG_ID_SPECIFIED = 2;
const USER_ID_SPECIFIED = 3;
const UNASSIGNED_SONGS = 4;
const LOGIN_POPUP = 'Log In';
const SIGNUP_POPUP = 'Sign Up';
const LOGOUT = 'Log Out';
const SET_PASSWORD_POPUP = 'Set Password';
const AZAA_PLATFORM_FEE_PERCENT = 15;
// zero means no limit
const MAX_UPLOADS = 
        {
        BASIC: 1,
        PLUS: 0,
        ULTRA: 0};
export {
    VALID_FILE_TYPES,
    AZAA_PLATFORM_FEE_PERCENT,
    MAX_SIZE, 
    MAX_UPLOADS,
    ACCEPTED_SONGS,
    SONG_ID_SPECIFIED,
    USER_ID_SPECIFIED,
    LOCAL_API_PATH,
    LOGIN_POPUP,
    SIGNUP_POPUP,
    SET_PASSWORD_POPUP,
    LOGOUT
};