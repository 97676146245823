import styled from "styled-components";

export const Wrapper = styled.div`

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;


`;

export const Content = styled.div`
margin: 60px 0;
display: flex;
width: 300px;
flex-direction: column;
align-items: center;
justify-content: center;
img{
    width: 100%;
}

/* input{
    
    height: 49px;
    padding: 0 20px 0 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    width: 100%;
    background: var(--greyBackground);
    border: none;
    letter-spacing: .1rem;
    box-shadow: inset 0 0 5px 1px rgb(0, 0, 0);

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 1px solid white;
        box-shadow: inset 0 0 5px rgba(255, 255, 255, .5);
        
    }


} */

button{
        width: 100%;
        margin-top: 20px;
        text-transform: uppercase;
        letter-spacing: .1rem;
        color: white;
        height: var(--buttonHeight);
        font-size: 11px;
        background: transparent;
        border: 1px solid var(--white);
        cursor: pointer;

        :hover{
            background: rgba(255,255,255,0.15)
        }
    }
    
    form{
        width: 100%;
    }

`;

export const Text = styled.div`
    margin-top: 60px;
    font-size: var(--fontMedium);
`;

export const InputFieldWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    position: relative;
    height: 49px;
    img{
        right: 20px;
        height: 22px;
        width: 33px;
        position: absolute;

    }

`;