import React, { useEffect, useState, useRef } from 'react'

import {
    Wrapper,
    SideBar,
    SideBarLink,
    RightContainer,
    SideBarLinkHeader,
    Row,
    Innercontainer,
    DateWrapper,
    SiteHeader,
    Spacer,
    MessagesLink,
    LoadingWheel,
    NavArrow,
    LoadWrapper,
    Logout
} from './AdminPanel.styles'
import dayjs from 'dayjs'
import FilterBar from './FilterBar'
import SongwritersTab from './SongwritersTab'
import FooterPlayBar from './FooterPlayBar'
import Songwriter from './Songwriter'
import Messages from '../Messages'
import FormNavArrow from '../../images/formNavArrow.svg';
import { returnMonth } from '../../helpers'

import { useAuth } from '../../contexts/AuthContext'

import axios from 'axios'
import API from '../../API';

const Content = ({ onSkip, handleSongAssignment, songStatusKey, adminID, contentToServe, values, loading, error, handleArtistAssignment, setProcessArtist, playing, nowPlaying, handleSongChange }) => {
    if (contentToServe.type !== 'messages') {
        return (<SongwritersTab
            type={contentToServe.type}
            isApproved={contentToServe.value === "New Applicants" || contentToServe.value === "New Songs" ? false : true}
            songs={
                values
                // values === null ? [] :
                //     contentToServe.value === "New Applicants" ?
                //         values.filter(song => { return song.status === "1" && parseInt(song.user_status) !== 1 }) :
                //         contentToServe.value === "New Songs" ?
                //             values.filter(song => { return song.status === "1" && parseInt(song.user_status) === 1 }) :
                //             values.filter(song => { return song.status !== "1" })

            }
            loading={loading}
            setProcessArtist={setProcessArtist}
            handleSongAssignment={handleSongAssignment}
            handleArtistAssignment={handleArtistAssignment}
            error={error}
            nowPlaying={nowPlaying}
            songStatusKey={songStatusKey}
            onSkip={onSkip}
            playing={playing}
            handleSongChange={handleSongChange}
        />
        )
    }
    else if (contentToServe.type === 'messages') {
        return <Messages
            userType="admin"
            acceptedSongwriters={values !== null ?
                values.filter(song => { return song.status !== "1" }).map(item => ({
                    user_id: item.user_id,
                    name: item.name,
                    song_id: item.song_id !== undefined ? item.song_id : item.id,
                    song_title: item.song_title,
                    email: item.email
                })) : []
            }
            value={contentToServe.value}
            userID={adminID}></Messages>
    }
    else {
        return <></>
    }



}

const ContentBox = ({ filter, pullSongs, totalResults, resultsPerPage, page, setPage, onSkip, handleSongChange, loading, content, todaysDate, handleSongAssignment, adminID, handleArtistAssignment, setProcessArtist, nowPlaying, playing, error, songs, songStatusKey }) => {


    if (!loading) {
        return (
            <Innercontainer>
                <Row>
                    <div style={{ gridColumn: '1 / span 2' }}>{content.value}</div>
                    {content.type !== 'messages' && <><div style={{ fontSize: '10px', display: 'flex', columnGap: '20px', padding: '0' }}>
                        <div style={{ display: 'flex' }}>Page {page} of <div style={{ display: 'flex', width: 'min-content', marginLeft: '3px' }}
                            onClick={() => {
                                if (page !== Math.ceil(totalResults / resultsPerPage)) {
                                    setPage(Math.ceil(totalResults / resultsPerPage))
                                    pullSongs(Math.ceil(totalResults / resultsPerPage), filter)
                                }
                            }}>
                            {Math.ceil(totalResults / resultsPerPage) !== 0 ? Math.ceil(totalResults / resultsPerPage) : 1}
                        </div></div>
                        <NavArrow back isValid={page > 1}
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1)
                                    pullSongs(page - 1, filter)
                                }
                            }}><img src={FormNavArrow} alt="back" /></NavArrow>
                        <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1}
                            onClick={() => {
                                if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) {
                                    setPage(page + 1)
                                    pullSongs(page + 1, filter)
                                }
                            }}><img src={FormNavArrow} alt="next" /></NavArrow>
                    </div>
                        <DateWrapper>{todaysDate}</DateWrapper></>}

                </Row>
                <Content
                    handleSongAssignment={handleSongAssignment}
                    adminID={adminID}
                    onSkip={onSkip}
                    setProcessArtist={setProcessArtist}
                    handleArtistAssignment={handleArtistAssignment} //testing email sendAcceptanceEmailTest. change back to  after
                    handleSongChange={handleSongChange}
                    nowPlaying={nowPlaying}
                    playing={playing}
                    error={error}
                    loading={loading}
                    values={songs}
                    songStatusKey={songStatusKey}
                    contentToServe={content} />
            </Innercontainer>)
    }
    else {
        return (
            <Innercontainer>
                <Row>
                    <div style={{ gridColumn: '1 / span 2' }}>{content.value}</div>
                    {content.type !== 'messages' && <><div style={{ fontSize: '10px', display: 'flex', columnGap: '20px', padding: '0' }}>
                        <div style={{ display: 'flex' }}>Page {page} of <div style={{ display: 'flex', width: 'min-content', marginLeft: '3px' }}
                        // onClick={() => {
                        //     if (page !== Math.ceil(totalResults / resultsPerPage)) {
                        //         setPage(Math.ceil(totalResults / resultsPerPage))
                        //         pullSongs()
                        //     }
                        // }}
                        >
                            {Math.ceil(totalResults / resultsPerPage) !== 0 ? Math.ceil(totalResults / resultsPerPage) : 1}
                        </div></div>
                        <NavArrow back isValid={page > 1}
                        // onClick={() => {
                        //     if (page > 1) {
                        //         setPage(page - 1)
                        //         pullSongs()
                        //     }
                        // }}
                        ><img src={FormNavArrow} alt="back" /></NavArrow>
                        <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1}
                        // onClick={() => {
                        //     if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) {
                        //         setPage(page + 1)
                        //         pullSongs()
                        //     }
                        // }}
                        ><img src={FormNavArrow} alt="next" /></NavArrow>
                    </div>
                        <DateWrapper>{todaysDate}</DateWrapper></>}
                </Row>
                <LoadWrapper><LoadingWheel></LoadingWheel></LoadWrapper>
            </Innercontainer>
        )
    }
}

const AdminPanel = () => {
    const [file, setFile] = useState(null)
    const [adminID, setAdminID] = useState('1')
    const [playBarSong, setPlayBarSong] = useState(null);
    const playRef = useRef(null)
    const audioRef = useRef(null)
    const [playing, setPlaying] = useState(false);
    const [nowPlaying, setNowPlaying] = useState(null);
    const { currentUser, logout } = useAuth()
    const resultsPerPage = 20
    const [page, setPage] = useState(1)
    const [totalResults, setTotalResults] = useState(0)
    useEffect(() => {
        if (audioRef.current) {
            //console.log(audioRef)
            playing ? audioRef.current.play().catch((e) => {
                console.log("error: " + e)
            }) : audioRef.current.pause();
        }

    },
        [playing, nowPlaying]
    );

    async function importFile(path) {
        //https://api.426xz11b.testdomain415.site/
        //setFile('http://nfttest.xyz/api' + path) //http://nfttest.xyz/api/index.php/home/get_songs
        setFile(`${process.env.REACT_APP_API_DOMAIN}` + path) //http://nfttest.xyz/api/index.php/home/get_songs
    }

    const handleSongChange = (songPath, songID) => {

        importFile(songPath);
        if (songID !== nowPlaying && nowPlaying !== null) {
            setPlaying(true)
            setNowPlaying(songID)
            playRef.current = songID

        }
        else if (nowPlaying === null) {

            setPlaying(true)
            setNowPlaying(songID)
            playRef.current = songID
        }
        else {

            setPlaying(!playing)
            setNowPlaying(songID)
            playRef.current = songID
        }
    }

    const sidebarlinks = {
        songwriters:
            ["New Applicants", "Accepted"],
        songs: ["New Songs", "Accepted"]
    };//, "Rejected", "Song Submissions"];
    const [content, setContent] = useState({
        type: "songwriters",
        value: sidebarlinks.songwriters[0]
    })
    const [songs, setSongs] = useState([]);
    const [filteredSongs, setFilteredSongs] = useState([]);
    const [filter, setFilter] = useState(1)
    const [songStatusKey, setSongStatusKey] = useState(null);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const now = new Date().toLocaleString().split(',')[0]
    const todaysDate = "Today's Date: " + dayjs().format('ddd, MMMM D, YYYY')//returnMonth(now, true) + ' ' + now.split('/')[1];
    const [processArtist, setProcessArtist] =
        useState({
            userID: null,
            songID: null,
            status: null,
            email: '',
            name: null,
            songTitle: null
        })
    const initial = useRef(true)

    const handleSongAssignment = () => {
        if (processArtist.status === '0') {
            const songIsPlaying = processArtist.songID === nowPlaying
            if (songIsPlaying) {
                setFile(null)
                setNowPlaying(null)
                setPlaying(false)
            }
        }
        API.processSongRegUser(currentUser, processArtist.songID, processArtist.status).then((res) => {
            pullSongs()
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleArtistAssignment = () => {
        var axios = require('axios');
        var qs = require('qs');
        var data2 = qs.stringify({
            'user_id': processArtist.userID,
            'status': processArtist.status,
            'song_id': processArtist.songID
        });
        if (processArtist.status === '0') {
            const songIsPlaying = processArtist.songID === nowPlaying
            if (songIsPlaying) {
                setFile(null)
                setNowPlaying(null)
                setPlaying(false)
            }
        }
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_DOMAIN}/user_status_update`, //https://426xz11dbtest123.testdomain415.site/api
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cookie': 'ci_session=b52152b3c80d9dde2c53f0649d552f74f4eefe68'
            },
            data: data2
        };
        axios(config)
            .then(function (response) {
                pullSongs()
            })
            .catch(function (error) {

            });

    }
    const sendAcceptanceEmailTest = async () => {
        try {
            setError(false);
            setLoading(true);
            const res = await API.sendSongAcceptanceEmail(processArtist.userID, processArtist.name, processArtist.email, processArtist.songTitle);
            //console.log(res)
        } catch (error) {
            setError(true);
            //console.log("error", error)
        }
        setLoading(false);
    };

    console.log(processArtist, "process artist")

    const sendAcceptanceMessage = () => {
        var axios = require('axios');
        var qs = require('qs');
        var data2 = qs.stringify({
            'receiver_id': processArtist.userID,
            'sender_id': '1',
            'songwriter_user_id': processArtist.userID,
            'song_id': processArtist.songID,
            'message': 'Congratulations! Your song has been accepted.'
        });
        // 
        //http://nfttest.xyz/api
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_DOMAIN}/save_messages`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data2
        };
        axios(config)
            .then(function (response) {

                sendAcceptanceEmailTest()
            })
            .catch(function (error) {
                //console.log(error);
            });
    }

    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return;
        }

    }, [processArtist])
    const pullSongs = (_page = page, _filter = filter) => { //http://nfttest.xyz/api/index.php/home/user_status_update
        setLoading(true)
        setError(false)
        API.fetchSongs(currentUser, _page, resultsPerPage, _filter).then((response) => {
            if (response.data.songs !== undefined) {
                setSongs(response.data.songs)
                setTotalResults(response.data.total)
            } else if (response.data.artists !== undefined) {
                setSongs(response.data.artists)
                setTotalResults(response.data.total)
            } else {
                setSongs([])
                setTotalResults(0)
            }
            setLoading(false)
            setError(false)
        })
            .catch(error => {
                // alert(error.response.data.message)
                //console.log(error);
                setError(true)
            });
    };
    const onSkip = (dir) => {

        // const currSongIdx = filteredSongs.findIndex(song => song.song_id === nowPlaying)
        // const adjustedIndex = dir + currSongIdx
        // const s_idx = adjustedIndex >= filteredSongs.length ? 0 : adjustedIndex < 0 ? filteredSongs.length - 1 : adjustedIndex;
        // const newSong = filteredSongs[s_idx];
        // console.log("on skip triggered", currSongIdx, nowPlaying, filteredSongs, s_idx)
        // handleSongChange(newSong.file_path, newSong.song_id)
        const currSongIdx = songs.findIndex(song => song.song_id === nowPlaying)
        const adjustedIndex = dir + currSongIdx
        const s_idx = adjustedIndex >= songs.length ? 0 : adjustedIndex < 0 ? songs.length - 1 : adjustedIndex;
        const newSong = songs[s_idx];
        console.log("on skip triggered", currSongIdx, nowPlaying, songs, s_idx)
        handleSongChange(newSong.file_path, newSong.song_id)
    }

    useEffect(() => {
        const fetch_song_statuses = async () => {
            try {
                const valid_statuses = await API.fetchSongStatusKey();
                setSongStatusKey(valid_statuses)
                //console.log(valid_statuses)
            } catch (error) {
                //console.log(error)
            }
        }
        fetch_song_statuses().then(() => { pullSongs(); })
        //API.fetchMessagesAdmin(currentUser.accessToken, currentUser.email, null, null)
    }, [])
    // useEffect(() => {
    //     if (songStatusKey !== null) {
    //         console.log("filter is", filter)
    //         pullSongs()
    //     }

    // }, [page, filter])
    const onSkip2 = (dir) => {

        // const currSongIdx = filteredSongs.findIndex(song => song.song_id === playRef.current)
        // const adjustedIndex = dir + currSongIdx
        // const s_idx = adjustedIndex >= filteredSongs.length ? 0 : adjustedIndex < 0 ? filteredSongs.length - 1 : adjustedIndex;
        // const newSong = filteredSongs[s_idx];
        // console.log("on skip triggered", currSongIdx, playRef.current, filteredSongs, s_idx)
        // handleSongChange(newSong.file_path, newSong.song_id)
        const currSongIdx = songs.findIndex(song => song.song_id === playRef.current)
        const adjustedIndex = dir + currSongIdx
        const s_idx = adjustedIndex >= songs.length ? 0 : adjustedIndex < 0 ? songs.length - 1 : adjustedIndex;
        const newSong = songs[s_idx];
        handleSongChange(newSong.file_path, newSong.song_id)
    }
    // useEffect(() => {

    //     // Add event listener
    //     const c = (e) => {
    //         if (e.code === 'MediaTrackNext') { onSkip2(1) }
    //     }
    //     window.addEventListener('keydown', c);

    //     // Remove event listener on cleanup
    //     return () => {
    //         window.removeEventListener('keydown', c);
    //     };
    // }, [])
    // console.log("nowPlaying", nowPlaying, filteredSongs)



    //if (loading) {
    return (
        <Wrapper>
            <SideBar>
                <SiteHeader>Azaa Admin</SiteHeader>
                <Spacer>
                    <SideBarLinkHeader>Songwriters</SideBarLinkHeader>
                    {sidebarlinks.songwriters.map(
                        (value, index) => (
                            <SideBarLink
                                value={value}
                                key={index}
                                isActive={content.value === value && content.type === "songwriters" ? true : false}
                                onClick={(e) => {
                                    setContent({
                                        type: "songwriters",
                                        value: value
                                    })
                                    //console.log("value", value === sidebarlinks.songwriters[0])
                                    setPage(1)
                                    setFilter(value === "New Applicants" ? 1 : 4)
                                    pullSongs(1, value === "New Applicants" ? 1 : 4)
                                    // setFilteredSongs([...songs.filter(song => { return song.status === "1" && parseInt(song.user_status) !== 1 })])
                                }
                                }
                            >
                                {value}
                            </SideBarLink>))}
                </Spacer>
                <Spacer>
                    <SideBarLinkHeader>Songs</SideBarLinkHeader>
                    {sidebarlinks.songs.map(
                        (value, index) => (
                            <SideBarLink
                                value={value}
                                key={index}
                                isActive={content.value === value && content.type === "songs" ? true : false}
                                onClick={(e) => {
                                    setContent({
                                        type: "songs",
                                        value: value
                                    })
                                    setPage(1)
                                    setFilter(value === "New Songs" ? 2 : 3)
                                    pullSongs(1, value === "New Songs" ? 2 : 3)
                                    // setFilteredSongs([...value === "New Songs" ?
                                    //     songs.filter(song => { return song.status === "1" && parseInt(song.user_status) === 1 }) :
                                    //     songs.filter(song => { return song.status !== "1" })])
                                }}
                            >
                                {value}
                            </SideBarLink>))}
                </Spacer>
                <Spacer>
                    <SideBarLinkHeader>Messages</SideBarLinkHeader>
                    <SideBarLink
                        value={"messages"}
                        isActive={content.type === "messages" && content.value === "songwriters" ? true : false}
                        onClick={(e) => (setContent({
                            type: "messages",
                            value: "songwriters"
                        }))}
                    >Songwriters</SideBarLink>

                </Spacer>
                <Logout onClick={() => logout()}><SideBarLink>Log Out</SideBarLink></Logout>
            </SideBar>
            <RightContainer isMessages={false}>
                <ContentBox
                    filter={filter}
                    handleSongAssignment={handleSongAssignment}
                    adminID={adminID}
                    setProcessArtist={setProcessArtist}
                    handleArtistAssignment={handleArtistAssignment} //testing email sendAcceptanceEmailTest. change back to  after
                    handleSongChange={handleSongChange}
                    nowPlaying={nowPlaying}
                    playing={playing}
                    error={error}
                    loading={loading}
                    songs={songs}
                    todaysDate={todaysDate}
                    onSkip={onSkip}
                    totalResults={totalResults}
                    resultsPerPage={resultsPerPage}
                    setPage={setPage}
                    pullSongs={pullSongs}
                    page={page}
                    values={songs}
                    content={content}
                    songStatusKey={songStatusKey}
                    contentToServe={content}
                />

            </RightContainer>
            <audio preload="none" ref={audioRef} src={file} onEnded={() => onSkip(1)}>
            </audio>
        </Wrapper>
    )
    // }
    // else {
    //     return (
    //         <Wrapper>
    //             <SideBar>
    //                 <SiteHeader>Azaa Admin</SiteHeader>
    //                 <Spacer>
    //                     <SideBarLinkHeader>Songwriters</SideBarLinkHeader>
    //                     {sidebarlinks.songwriters.map(
    //                         (value, index) => (
    //                             <SideBarLink
    //                                 value={value}
    //                                 key={index}
    //                                 isActive={content.value === value && content.type === "songwriters" ? true : false}
    //                                 onClick={(e) => (setContent({
    //                                     type: "songwriters",
    //                                     value: value
    //                                 }))}
    //                             >
    //                                 {value}
    //                             </SideBarLink>))}
    //                 </Spacer>
    //                 <Spacer>
    //                     <SideBarLinkHeader>Songs</SideBarLinkHeader>
    //                     {sidebarlinks.songs.map(
    //                         (value, index) => (
    //                             <SideBarLink
    //                                 value={value}
    //                                 key={index}
    //                                 isActive={content.value === value && content.type === "songs" ? true : false}
    //                                 onClick={(e) => (setContent({
    //                                     type: "songs",
    //                                     value: value
    //                                 }))}
    //                             >
    //                                 {value}
    //                             </SideBarLink>))}
    //                 </Spacer>
    //                 <Spacer>
    //                     <SideBarLinkHeader>Messages</SideBarLinkHeader>
    //                     <SideBarLink
    //                         value={"messages"}
    //                         isActive={content.type === "messages" && content.value === "songwriters" ? true : false}
    //                         onClick={(e) => (setContent({
    //                             type: "messages",
    //                             value: "songwriters"
    //                         }))}
    //                     >Songwriters</SideBarLink>
    //                     <SideBarLink
    //                         value={"messages"}
    //                         isActive={content.type === "messages" && content.value === "buyers" ? true : false}
    //                         onClick={(e) => (setContent({
    //                             type: "messages",
    //                             value: "buyers"
    //                         }))}
    //                     >Buyers</SideBarLink>
    //                 </Spacer>

    //             </SideBar>
    //             <RightContainer isMessages={content.type === "messages" ? true : false}>
    //                 <ContentBox loading={loading} />
    //             </RightContainer>
    //             <audio preload="none" ref={audioRef} src={file} >
    //             </audio>
    //         </Wrapper>
    //     )
    // }

}

export default AdminPanel
