import React from 'react'

import { LoadingWheelWrapper } from './LoadingWheel.styles'

const LoadingWheel = ({border}) => {
    return (
        <LoadingWheelWrapper border={border}>
            
        </LoadingWheelWrapper>
    )
}

export default LoadingWheel
