import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
position: relative;
margin-bottom: 20px;
padding-right: 5%;
grid-template-columns: min-content 1fr 200px;
grid-template-rows: min-content min-content;
grid-column-gap: 10px;
grid-template-areas:
    'splitSymbol title nextStep';
`;

export const SplitSymbol = styled.div`
grid-area: splitSymbol;
display: flex;
width: 100%;
position: relative;
height: 100%;
img{
    width: 60px;
}
`;

export const Title = styled.div`
grid-area: title;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
`;

export const SubText = styled.div`
color: var(--purpleGrey);
font-size: 12px;
letter-spacing: 0.03rem;
`;

export const NextStepTextWrapper = styled.div`
white-space: nowrap;
display: flex;
flex-direction: column;
height: 100%;
position: relative;
justify-content: space-between;
`;

export const NextStep = styled.div`
user-select: none;
justify-content: flex-end;
height: 100%;
align-items: center;
display: flex;
grid-area: nextStep;
img{
    height: 15px;
    margin-left: 5px;
}
`;

export const Next = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
`;