import styled from "styled-components";

export const OuterWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;

width: 100%;
height: 100%;
position: relative;
padding: 50px;
top: 0px;
`;

export const Header = styled.h1`

font-weight: 200;
text-transform: uppercase;
`;

export const Wrapper = styled.div`
display: grid;
max-width: 800px;
position: relative;
grid-column-gap: 20px;
grid-row-gap: 20px;
grid-template-areas:
${props => props.index === 0 ? 
`'image quote'
'image bio'` : 
`'quote image'
'bio image'`
} ;
grid-template-columns: 
${props => props.index === 0 ? '400px 1fr' : '1fr 400px' };
`;

export const Quote = styled.div`
font-style: italic;
font-size: 21px;
font-weight: 200;
`;

export const Attribute = styled.div`
font-size: 12px;
text-transform: uppercase;
margin-top: 1rem;
`;

export const Bio = styled.div`
text-align: justify;
`;

export const PictureContainer = styled.div`
grid-area: image;
position: relative;
display: flex;

overflow: hidden;
img{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
`;

export const QuoteBox = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
`;



