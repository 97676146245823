import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
left: 0;
display: flex;
position: absolute;
grid-template-columns: 50px 50px;
grid-template-areas: ${props => props.isApproved ? `'reject reject'` : `'accept reject'`};
grid-column-gap: 10px;
`;

export const PopupWrapperOverlay = styled.div`
position: fixed;
display: flex;
justify-content: center;
align-items: center;
width: ${props => props.visible ? '100%' : '0'};
height: ${props => props.visible ? '100%' : '0'};
overflow: ${props => props.visible ? 'hidden' : 'visible'};
top: 0;
left: 0;
background: rgba(0,0,0,0.75);
background: transparent;
z-index: 2;
visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

export const ButtonContainer = styled.div`

height: 100%;
display: grid;
grid-template-columns: ${ props => props.items === 1 ? '1fr' : '1fr 1fr'};
grid-column-gap: 20px;
width: 100%;
margin-top: 20px;
`;

export const Row = styled.div`
display: flex;
justify-content: space-between;`;

export const PopupWrapper = styled.div`
width: 90%;
max-width: 600px;
min-width: min-content;
display: grid;
color: #ffffff;
grid-template-rows: repeat(4,min-content);
z-index: 1;
visibility: ${props => props.visible ? 'visible' : 'hidden'};
grid-row-gap: 10px;
text-align: center;
padding: 20px;
user-select: none;

position: absolute;
background: var(--greyBackground);
`;
export const Subtext = styled.div`
display: flex;
text-align: left;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const ValueHighlighted = styled.div`
font-weight: 600;
text-transform: none;
letter-spacing: 0.03rem;

`;

export const ConfirmResponse = styled.div`
margin-top: 20px;`;

export const Strong = styled.strong`
color: ${props => props.action === 'accept' ? '#03cc00' : props.action === 'reject' ? '#9e0000' : '#ffffff'};
text-transform: ${props => props.action !== '' ? 'uppercase' : 'undefined'};
`;

export const Blurb = styled.div`
font-size: 14px;
text-align: left;
margin-top: 20px;
`;


export const Title = styled.div`
text-align: left;
white-space: nowrap;
font-size: 22px;
`;
export const Action = styled.div`
img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const AcceptButtonContainer = styled(Action)`

`;


export const RejectButtonContainer = styled(Action)`
grid-area: reject;
`;

export const SubTitle = styled.div`
font-size: 18px;
margin-bottom: 20px;
text-align: left;
`;

export const CloseButtonContainer = styled.div`
display: flex;
width: 100%;
justify-content: flex-end;
position: relative;
img{
height: 20px;
width: 20px;
cursor: pointer;
}
`;

export const TableContainer = styled.div`
margin: 20px 0;
border: 1px solid #ffffff;

`;

export const PricingTable = styled.div`
font-size: 14px;
width: 100%;
display: grid;
padding: 0 20px;
grid-column-gap: 20px;
grid-template-columns: 1fr 5rem 5rem;
grid-auto-rows: min-content;
border: 1px solid #2f2f2f;

>div{
    white-space: nowrap;
    text-align: right;
    height: 30px;
    display: flex;
    align-items: center;
    color: var(--purpleGrey);
    /* padding: 5px; */
    /* padding: 1px 0; */
}

>div:nth-of-type(2n + 1){
    /* padding: 5px;// 5px 0 5px; */

    /* letter-spacing: 0.03rem; */
    font-size: 12px;
    padding-left: 20px;
    grid-column: 1 / span 1;
    
    font-weight: 600;
    justify-content: flex-end;
}
>div:nth-of-type(2n){
    font-weight: 500;
    /* padding-right: 20px; */
    grid-column: 2 / span 1;
    /* letter-spacing: .1rem; */
    /* padding: 5px 25px 5px; */
    justify-content: flex-start;
    
    
}
/* &:last-child{
    border-bottom: 0;
} */
/* >div:nth-of-type(4), >div:nth-of-type(3){
    border-bottom: 2px solid var(--greyBackground);
} */
`;