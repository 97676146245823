import axios from 'axios'
//http://nfttest.xyz/api/index.php/home/
import {
  ACCEPTED_SONGS,
  SONG_ID_SPECIFIED,
  USER_ID_SPECIFIED,
  LOCAL_API_PATH
} from './config';

const defaultConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

const apiSettings = {
  fetchUsers: async (currentUser) => {
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_users?email=${currentUser.email}&access_token=${currentUser.accessToken}`
    });

    return res
  },
  fetchSongs: async (currentUser, page = undefined, resultsPerPage = undefined, filter = undefined, filter_arr = null) => {
    const params = {
      email: currentUser.email,
      access_token: currentUser.accessToken,
      c: resultsPerPage,
      p: page,
      filter: filter
    }

    let res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_songs`,
      { params: { ...Object.fromEntries(Object.entries(params).filter(it => it[1] !== undefined)) } })

    // let res = await axios({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_API_DOMAIN}/get_songs?access_token=${currentUser.accessToken}&email=${currentUser.email}&p=${page}&c=${resultsPerPage}`
    // });
    return res
    // let res = await axios({
    //   method: 'get',
    //   url: 'https://api.426xz11b.testdomain415.site/get_songs'
    // });

    // let data = res.data.songs.reverse();


    // if (filter === ACCEPTED_SONGS || filter === SONG_ID_SPECIFIED || filter === USER_ID_SPECIFIED) {
    //   const accepted_songs = data.filter(song => { return song.status !== '1' })
    //   if (filter === ACCEPTED_SONGS) return accepted_songs
    //   else if (filter === SONG_ID_SPECIFIED && filter_arr != null)
    //     return accepted_songs.filter(song => { return filter_arr.includes(song.song_id) })
    //   else if (filter === USER_ID_SPECIFIED && filter_arr != null)
    //     return accepted_songs.filter(song => { return filter_arr.includes(song.user_id) })
    // }
    // else {
    //   return data
    // }

  },
  setAccessToken: async (email, token) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'token': token
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/set_admin_access_token`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res
  },
  fetchSongStatusKey: async () => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_song_status_keys`
    });
    return res.data.valid_statuses
  },
  fetchMessages: async (userID, songID, page = 1, resultsPerPage = 5) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_messages?song_writer_id=${userID}&song_id=${songID}&p=${page}&c=${resultsPerPage}`
    });

    return [res.data.messages, res.data.user, res.data.song]

  },
  fetchMessagesAdmin: async (accessToken, email, page = 1, resultsPerPage = 5, userID = null, songID = null) => { //get_message_stream_headers
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_message_stream_headers2?access_token=${accessToken}&email=${email}&song_writer_id=${userID}&song_id=${songID}&p=${page}&c=${resultsPerPage}`
    });
    return [res.data.message_streams, res.data.total]
  },
  processSongRegUser: async (currentUser, songID, status) => { //registered, accepted user
    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/song_status_update?admin_email=${currentUser.email}&access_token=${currentUser.accessToken}&song_id=${songID}&status=${status}`
    });

    return res

  },
  sendMessage: async (receiver_id, sender_id, songwriter_id, song_id, message) => {
    var qs = require('qs');
    var data = qs.stringify({
      'receiver_id': receiver_id,
      'sender_id': '1',
      'songwriter_user_id': songwriter_id,
      'song_id': song_id,
      'message': message
    });
    let res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_DOMAIN}/save_messages`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data

    });

    return res
  },
  sendSongAcceptanceEmail: async (userID, name, email, songTitle) => {
    var qs = require('qs');
    var data = qs.stringify({
      'fname': name,
      'email': email,
      'song_title': songTitle,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/send_user_email2`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  sendSongAcceptanceEmailX: async (userID, name, email, songTitle) => {
    var qs = require('qs');
    var data = qs.stringify({
      'fname': name,
      'email': email,
      'song_title': songTitle,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/send_user_email2`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  authenticateAcceptance: async (userID) => {

    let res = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/get_users?user_id=${userID}`
    });

    return res.data.user

  },
  registerAccount: async (userID, email, password) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'password': password,
      'user_id': userID
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/register`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res

  },
  updateMessageReadStatus: async (song_id, receiver_id, user_id) => { //we only want new tags when current user === receiver
    let res = await axios({ //user_id is songwriter_user_id field
      method: 'get',
      url: `${process.env.REACT_APP_API_DOMAIN}/update_message_read_status?song_id=${song_id}&receiver_id=${receiver_id}&user_id=${user_id}`
    });
    return res
  },
  signIn: async (email, password) => {
    const qs = require('qs');
    const data = qs.stringify({
      'email': email,
      'password': password
    });
    let res = await axios({
      method: 'post', // & controllers
      url: `${process.env.REACT_APP_API_DOMAIN}/login`, //api/config/database.php
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    });

    return res
  }
};



export default apiSettings;