import styled from "styled-components";

export const Wrapper = styled.div`
display: grid;
user-select: none;
position: relative;
width: 100%;
font-size: 14px;
padding: 10px;
grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : ''};
grid-column-gap: ${props => props.gridColGap ? props.gridColGap : '10px'};

&:hover{
    background: ${props => props.isHeader || props.noHover ? 'transparent' : 'rgba(255,255,255,0.15)' };
}

/* div{
    display: flex;
    justify-content: ${ props => props.align ? props.align : 'flex-start'};
    text-align: ${ props => 
        props.align ? 
        props.align === 'flex-start' ? 'left' 
        : props.align === 'flex-end' ? 'right' 
        : props.align === 'center' ? 'center' : 'left' 
        : 'left'};
} */
`;

export const RowElementWrapper = styled.div`
    display: flex;
    justify-content: ${ props => props.align ? props.align : 'flex-start'};
    text-align: ${ props => 
        props.align ? 
        props.align === 'flex-start' ? 'left' 
        : props.align === 'flex-end' ? 'right' 
        : props.align === 'center' ? 'center' : 'left' 
        : 'left'};
`;