import styled from "styled-components";

export const ButtonStyle = styled.button`
    height: ${props => props.height ? props.height : 'var(--buttonHeight)'};
    font-size: var(--buttonFontSize);
    color: ${props => props.color ? props.color : props.validContinue ? 'var(--white)' : '#000'};
    width: ${ ({width}) => width};
    
    background: ${props => props.background};
    text-transform: uppercase;
    cursor: ${props => props.validContinue ? 'pointer' : 'default'};
    border: ${ ({border}) => border};
    border: ${ props =>  props.validContinue ? props.border : '2px solid rgba(255,255,255,0.1)'};
    background: ${props => props.validContinue ? props.background : 'rgba(255,255,255,0.15)'};
    font-weight: ${ props => props.validContinue ? '500' : '700'};
    letter-spacing: 0.08rem;
    :hover{
        background: ${props => props.hoverColor  ? props.hoverColor : 'rgba(255,255,255,0.15)'} ;

    }
`;