import React, { useState, useEffect, useRef } from 'react'

import {
    OuterWrapper,
    FlexColumn,
    Line,
    MessagesSideBar,
    SongStatusNote,
    MessageStream,
    Link,
    Title,
    LinkHeader,
    NavArrow,
    SubLink,
    DetailsWrapper,
    NewDot,
    SongDetailsHeader,
    LoadingWrapper,
    GridItem,
    ScrollWrapper,
    InnerScrollWrapper
} from './Messages.styles'

import axios from 'axios';
import API from '../../API';
import { toTitleCase, calculatePriceWithFee } from '../../helpers'

// Hook
import { useMessengerFetch } from '../../hooks/useMessengerFetch';

// Components
import MessageGrid from '../MessageGrid'
import MessageInputBar from '../MessageInputBar'
import SongDetailsTable from '../SongDetailsTable'
import LoadingWheel from '../LoadingWheel'

import FormNavArrow from '../../images/formNavArrow.svg';

const Conversation = ({ isNew, songID, songTitle, isCurrent, onClick }) => {
    const title = songID !== "1" ? songTitle : 'General';
    return (
        <SubLink isNew={isNew} isCurrent={isCurrent} onClick={onClick}>{title}
            {isNew && <NewDot />}
        </SubLink>
    )
}

const User = ({ messageHeaders, isCurrent, onClick, children, acceptedSongConversations, selectedMessage, setSelectedMessage, userID }) => {
    return (
        <>
            <LinkHeader
                isCurrent={isCurrent}
                onClick={onClick} >
                {children}
            </LinkHeader>
            {
                acceptedSongConversations.map(c => (
                    <Conversation
                        isNew={c.receiver_id === '1' && c.is_new === '1'}
                        isCurrent={selectedMessage.song_id === c.song_id && selectedMessage.user_id === c.user_id}
                        onClick={() => setSelectedMessage({ song_id: c.song_id, user_id: c.user_id, song: { ...c } })}
                        songID={c.song_id}
                        key={c.song_id}
                        songTitle={c.song_title} />
                ))
            }
        </>
    )
}

const Content = ({ messages, userID, loading }) => {
    if (loading) {
        return (
            <LoadingWrapper>
                <LoadingWheel />
            </LoadingWrapper>)
    }
    else return (
        <MessageGrid messages={messages} currUserID={userID} loading={loading} />
    )
}


const Messages = ({ userID, userType, value, acceptedSongwriters }) => {

    const { resultsPerPage, totalResults, page, setPage, messageHeaders, sendMessage, songDetails, selectedUser, continueKey, handleChange, messageInput, setMessageInput, loadingSideBar, setUserIDs, loading, error, messages, selectedMessage, setSelectedMessage } = useMessengerFetch(userID);
    const [loadMessages, setMessagesToLoad] = useState(null)
    if (!Object.keys(messageHeaders).length || loadingSideBar) {
        return <OuterWrapper></OuterWrapper>
    }
    else {
        return (
            <OuterWrapper>
                <GridItem gridArea="sideBar">
                    <MessagesSideBar>
                        <Title>Messages</Title>
                        <div style={{ display: 'flex', columnGap: '20px', padding: '0 20px 20px' }}><div style={{ display: 'flex' }}>Page {page} of <div style={{ display: 'flex', width: 'min-content', marginLeft: '3px' }} onClick={() => { if (page !== Math.ceil(totalResults / resultsPerPage)) setPage(Math.ceil(totalResults / resultsPerPage)) }}>{Math.ceil(totalResults / resultsPerPage)}</div></div>
                            <NavArrow back isValid={page > 1} onClick={() => { if (page > 1) { setPage(page - 1) } }}><img src={FormNavArrow} alt="back" /></NavArrow>
                            <NavArrow isValid={page <= Math.ceil(totalResults / resultsPerPage) - 1} onClick={() => { if (page + 1 <= Math.ceil(totalResults / resultsPerPage)) { setPage(page + 1) } }}><img src={FormNavArrow} alt="next" /></NavArrow>
                        </div>
                        <ScrollWrapper>
                            <InnerScrollWrapper>
                                {Object.keys(messageHeaders).map((user, index) =>
                                (
                                    <User
                                        messageHeaders={messageHeaders}
                                        setSelectedMessage={setSelectedMessage}
                                        userID={messageHeaders[user][0].user_id}
                                        selectedMessage={selectedMessage}
                                        isCurrent={loadMessages === messageHeaders[user][0].user_id}
                                        onClick={() => setMessagesToLoad(messageHeaders[user][0].user_id)}
                                        acceptedSongConversations={messageHeaders[user]}
                                        key={index}>
                                        {user.slice(0, 20) + '...'}
                                    </ User>

                                )
                                )}
                            </InnerScrollWrapper>
                        </ScrollWrapper>
                    </MessagesSideBar>

                </GridItem>
                {/* <MessageStream> */}
                <GridItem gridArea="details">
                    {songDetails === null &&
                        <DetailsWrapper >
                            <SongDetailsHeader >
                                <div>General<SongStatusNote> </SongStatusNote></div>
                            </SongDetailsHeader>

                        </DetailsWrapper>}
                    {(songDetails !== null && songDetails !== undefined) &&
                        <DetailsWrapper >
                            <SongDetailsHeader >
                                <div>{songDetails.song_title}<SongStatusNote>{songDetails.status_text}</SongStatusNote></div>
                                {parseInt(songDetails.song_status) >= 3 &&

                                    <div textAlign="right">{`${calculatePriceWithFee(songDetails.price).total}`}<SongStatusNote textAlign="right">list price</SongStatusNote></div>
                                }
                            </SongDetailsHeader>
                            {(parseInt(songDetails.song_status) >= 3 || (parseInt(songDetails.song_status) === 2 && songDetails.contributors !== undefined)) &&
                                <FlexColumn>
                                    <Line />
                                    <SongDetailsTable padding="20px 0 0" margin="0" border="undefined" borderBottom="none" selectedSong={songDetails} />
                                </FlexColumn>
                            }
                        </DetailsWrapper>}
                </GridItem>
                <GridItem gridArea="messages" overflow="hidden">
                    <DetailsWrapper>
                        <SongDetailsHeader >
                            <div>
                                {songDetails !== null && songDetails !== undefined ?
                                    <>{songDetails.song_title}</> :
                                    <>General</>
                                }
                                {<SongStatusNote>{selectedUser !== '' ?
                                    <>{`${selectedUser.first_name} ${selectedUser.last_name} | `}<a style={{all: 'unset', cursor: 'pointer'}} href={`mailto:${selectedUser.email}`}>{selectedUser.email}</a></>
                                    : "Messages"}</SongStatusNote>}
                            </div>
                        </SongDetailsHeader>
                        <Line />
                    </DetailsWrapper>
                    <Content loading={loading} messages={messages} userID={userID} />
                </GridItem>
                <GridItem gridArea="inputbar" background="transparent">
                    <MessageInputBar setMessageInput={setMessageInput} onKeyPress={continueKey} sendMessage={sendMessage} onChange={handleChange} messageInput={messageInput.message} />
                </GridItem>
                {/* </MessageStream> */}

            </OuterWrapper >
        )
    }

}

export default Messages
