import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    overflow: hidden;
`;
export const SideBar = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.03rem;
    width: 30%;
    max-width: 200px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 0px 20px;
    background: var(--greyBackground);
`;

export const SideBarLink = styled.div`
    padding: 10px 20px;
    background: ${props => props.isActive ? 'var(--backgroundActiveLi)' : 'transparent'};
    border-right: ${props => props.isActive ? 'var(--borderRightActiveLi)' : 'none'};
`;

export const MessagesLink = styled(SideBarLink)`
    font-size: 15px;
    font-weight: 500;
`;

export const SideBarLinkHeader = styled.div`
    padding: 10px 20px;
    font-weight: 500;
    font-size: 15px;
`;

export const Spacer = styled.div`
margin-top: 30px;
`;

export const RightContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    padding: ${props => props.isMessages ? '50px 20px' : '50px'};
    align-items: center;
`;

export const Row = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: min-content min-content;
row-gap: 10px;
width: 100%;
margin-bottom: 10px;
text-transform: uppercase;
`;

export const LoadWrapper = styled.div`
width: 100%;
height: 100%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid var(--greyBackground);
    margin-top: 2px;
`;

export const Innercontainer = styled.div`
width: 100%;
height: 100%;
position: relative;
`;

export const DateWrapper = styled.div`
font-size: 10px;
display: flex;
text-align: right;
justify-content: flex-end;
align-items: center;
`;

export const SiteHeader = styled.div`
    color: #fff;
    padding: 10px 20px;
    font-family: Cano;
    text-transform: none;
    font-size: 16px;
`;

export const Logout = styled.div`
    position: absolute;
    bottom: 20px;
`;

export const LoadingWheel = styled.div`
    border: 5px solid var(--greyBackground);
    border-top: 5px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`;

export const NavArrow = styled.div`
height: 15px;
width: 15px;
opacity: ${props => props.isValid ? '1' : '0.25'};
transform: ${props => props.back ? 'rotate(180deg)' : ''};
display: flex;
align-items: center;
justify-content: center;
img{
    height: 90%;
    width: 90%;
}
`;