import logo from './logo.svg';
import './App.css';
import Navigation from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Components
import Form from './components/UploadForm/Form';
import Footer from './components/Footer';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';
import Dashboard from './components/Dashboard'
import SetSplits from './components/SetSplits'
import Home from './components/Home'
import About from './components/About'
import Register from './components/Register'
import PrivateRoute from './components/PrivateRoute'
//import AuthUser from './components/AuthUser'
import { AuthProvider, useAuth } from './contexts/AuthContext'
import { GlobalStyle } from './GlobalStyle';


const AppUserStats = () => {
  const { currentUser } = useAuth();
  return <></>
}

function App() {

  return (
    <AuthProvider>
      <div className="App">
        <AppUserStats />
        <Router>
          {/* <Navigation /> */}
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<AdminPanel />} />
            </Route>
            <Route path="/register/:userID" exact element={<Register />} />
            <Route path="/login" exact element={<Login />} />
          </Routes>
        </Router>
        <GlobalStyle />
      </div>
    </AuthProvider>

  );
}



export default App;
