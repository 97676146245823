import styled from 'styled-components';

export const Wrapper = styled.div`
display: grid;
user-select: none;
font-size: 12px;
grid-template-areas: ${props => props.gridTemplateAreas ? `"${props.gridTemplateAreas}"` : ''};
grid-template-columns: ${props => props.gridTemplateCols ? props.gridTemplateCols : ''};
grid-column-gap: 10px;
padding: 20px;
align-content: center;
color: ${props => props.action === 'accept' ? '#03cc00' : props.action === 'reject' ? '#9e0000' : '#ffffff'};
justify-content: center;
background: ${props => props.action === 'accept' ? 'rgba(0,255,0,0.05)' : props.action === 'reject' ? 'rgba(255,0,0,0.05)' : props.isCurrent ? 'rgba(255,255,255,0.1)' : 'transparent'};
/* &:nth-child(2n){
        background:  rgba(255,255,255,0.1) ;
        } */
`;

export const ArtistName = styled.div`
    grid-area: artist;
    white-space: nowrap;
`;

export const Spotify = styled.a`
display: flex;
grid-area: spotify;
cursor: ${props => props.spotify ? 'pointer' : 'default'};
flex-direction: column;
align-items: center;
justify-content: center;
opacity: ${props => props.spotify ? '1' : '0.3'};
img{
    height: 20px;
}
`;

export const Instagram = styled.a`
display: flex;
grid-area: instagram;
cursor: ${props => props.instagram ? 'pointer' : 'default'};
flex-direction: column;
align-items: center;
justify-content: center;
opacity: ${props => props.instagram ? '1' : '0.3'};
img{
    height: 20px;
}
`;

export const SongTitle = styled.div`
grid-area: song;
`;

export const Tempo = styled.div`
grid-area: tempo;
`;

export const Email = styled.div`
grid-area: email;
`;

export const Genre = styled.div`
grid-area: genre;
`;

export const Date = styled.div`
grid-area: date;
display: flex;
justify-content: center;
`;

export const GridItem = styled.div`
grid-area: ${props => props.gridArea ? props.gridArea : ''};
`;

export const Status = styled.div`
grid-area: status;
font-size: 12px;
text-transform: uppercase;
letter-spacing: 0.03rem;
color: ${props => props.functionalStatus ? 'var(--yellow)' : ''};
cursor: ${props => props.functionalStatus ? 'pointer' : 'default'};
font-weight: ${props => props.functionalStatus ? '700' : ''};
`;

export const Action = styled.div`
img{
    width: 20px;
height: 20px;
}

position: relative;
display: flex;
align-items: center;
justify-content: center;
`;

export const AcceptButtonContainer = styled(Action)`

`;

export const RejectButtonContainer = styled(Action)``;

export const PlayButtonContainer = styled(Action)`
img{
    height: 10px;
}
`;