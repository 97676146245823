import React, { useState, useEffect, useRef } from 'react'

import Accept from '../../../images/acceptIcon.svg'
import Reject from '../../../images/rejectIcon.svg'
import Close from '../../../images/close.svg'

import {
    Wrapper,
    AcceptButtonContainer,
    RejectButtonContainer,
    PopupWrapperOverlay,
    PopupWrapper,
    Subtext,
    ButtonContainer,
    ValueHighlighted,
    CloseButtonContainer,
    Strong

} from './AcceptAndReject.styles'

import Button from '../../Button'

const AcceptButton = ({ onClick }) => (
    <AcceptButtonContainer onClick={onClick}>
        <img src={Accept} alt="accept-button" />
    </AcceptButtonContainer>
)

const RejectButton = ({ onClick }) => (
    <RejectButtonContainer onClick={onClick}>
        <img src={Reject} alt="reject-button" />
    </RejectButtonContainer>
)

const ClosePopup = ({ closePopup }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closePopup} />
    </CloseButtonContainer>
)

const Popup = ({ action, visible, type, value, closePopup, handleArtistAssignment }) => {
    const subtext = type === 'artist' ? ` this songwriter` : ` this song`;
    return (
        <PopupWrapperOverlay visible={visible}>
            <PopupWrapper visible={visible}>
                <ClosePopup closePopup={closePopup} />
                <div>
                    Please confirm that you would like to <ValueHighlighted><Strong action={action}>{action}</Strong>{subtext}</ValueHighlighted>
                </div>
                <Subtext><ValueHighlighted>{value}</ValueHighlighted></Subtext>
                <ButtonContainer>
                    <Button text={action} width="100%"
                        onClick={() => {
                            handleArtistAssignment()
                            closePopup()
                        }}
                        background="transparent" border="1px solid white" />
                </ButtonContainer>
            </PopupWrapper>
        </PopupWrapperOverlay>

    )
}
const AcceptAndReject = ({ isApproved, handleAssignment, handleArtistAssignment, type, value, popupVisible, action, closePopup }) => {


    return (
        <>
            <Wrapper isApproved={isApproved}>
                {!isApproved && <AcceptButton onClick={handleAssignment("accept")} />}
                <RejectButton onClick={handleAssignment("reject")} />
            </Wrapper>
            <Popup handleArtistAssignment={handleArtistAssignment} action={action} closePopup={closePopup} visible={popupVisible !== undefined ? popupVisible : false} type={type} value={value} />
        </>
    )
}

export default AcceptAndReject
