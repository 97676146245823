import React, { useState, useEffect, useRef } from 'react'

import Accept from '../../../images/acceptIcon.svg'
import Agreed from '../../../images/agreedIcon.svg'
import Disagreed from '../../../images/close.svg'
import Reject from '../../../images/rejectIcon.svg'
import Close from '../../../images/close.svg'
import { AZAA_PLATFORM_FEE_PERCENT } from '../../../config'
import {
    Wrapper,
    AcceptButtonContainer,
    RejectButtonContainer,
    PopupWrapperOverlay,
    PopupWrapper,
    Subtext,
    ButtonContainer,
    ValueHighlighted,
    CloseButtonContainer,
    Strong,
    Blurb,
    Title,
    TableContainer,
    SubTitle,
    ConfirmResponse,
    Row,
    PricingTable

} from './SongDetailsModal.styles'

import Button from '../../Button'
import Table from '../../Table'

import { adjustPrice, calculateCut, calculatePriceWithFee } from '../../../helpers'

import { useAuth } from '../../../contexts/AuthContext'

const ClosePopup = ({ closePopup }) => (
    <CloseButtonContainer >
        <img src={Close} alt="close-popup" onClick={closePopup} />
    </CloseButtonContainer>
)

const Content = ({ selectedSong, closePopup }) => {
    const showTrailing = selectedSong.price === '-' || selectedSong.price === '' || selectedSong.price === null || selectedSong.price === undefined || selectedSong.contributors.length === 0 ? false
        : parseFloat(selectedSong.price.replace(/,/g, '')) % 1 ? true
            : (parseFloat(selectedSong.price.replace(/,/g, '')) * (AZAA_PLATFORM_FEE_PERCENT) / 100.00) % 1 ? true
                : selectedSong.contributors.map(c => parseFloat(selectedSong.price.replace(/,/g, '')) * parseFloat(c.split) / 100.00).reduce((a, b) => { return b % 1 ? true : a }, false)
    const tableContent = selectedSong.contributors.map((content) => (
        {
            contentID: selectedSong.id,
            rowHighlight: undefined,
            contentArr: [
                content.email,
                `${content.split}%`,
                `${calculateCut(content.split, selectedSong.price,showTrailing)}`,
                content.agreement !== null ? <><img src={content.agreement === '1' ? Agreed : Disagreed} alt={content.agreement === '1' ? "agreed" : "disagreed"} style={{ width: "15px" }} /></> : '-'
            ]
        })
    )
    const rowAlignmentArray = ["flex-start", "center", "flex-start", "center"];
    return (
        <>
            {/* <SubTitle>Contributors & Splits</SubTitle> */}
            {parseInt(selectedSong.status) >= 3 && <Blurb>
                <Strong>'{selectedSong.song_title}'</Strong> will be listed at a total price of {`${calculatePriceWithFee(selectedSong.price,showTrailing).total}`} with the following splits.
            </Blurb>}
            {parseInt(selectedSong.status) === 2 &&
                <Blurb>
                    A contributor rejected listing <Strong>'{selectedSong.song_title}'</Strong> at a total price of {`${calculatePriceWithFee(selectedSong.price,showTrailing).total}`} with the following splits.
                </Blurb>
            }
            <TableContainer>
                <Table
                    height="min-content"
                    content={tableContent}
                    noHover={true}
                    tableHeaderTextStyling={{ textTransform: 'uppercase' }}
                    tableHeader={["Contributor", "Split [%]", "Split [$]", "Agreement"]}
                    gridTemplateColumns='1fr 60px 5rem 5rem'
                    rowAlignmentArray={rowAlignmentArray}
                    gridColGap="20px"
                    padding="10px 20px"
                />
                <PricingTable>
                    <div>Song Price</div><div>{`${adjustPrice(selectedSong.price,showTrailing)}`}</div>
                    <div>+ 15% Azaa Platform Fee</div><div> + {calculatePriceWithFee(selectedSong.price,showTrailing).fee}</div>
                    <div>Listing Price</div><div>{calculatePriceWithFee(selectedSong.price,showTrailing).total}</div>
                </PricingTable>
            </TableContainer>
            <ButtonContainer items={1}>
                <Button
                    text="Close"
                    width="100%"
                    onClick={() => {
                        console.log("closing")
                        closePopup()
                        //setPopupVisibility(false)
                    }}
                    background="transparent"
                    border="1px solid #ffffff" />
            </ButtonContainer>
        </>
    )
}

const Popup = ({ visible, closePopup, selectedSong }) => {
    const { currentUser } = useAuth();

    return (
        <PopupWrapperOverlay visible={visible} onClick={closePopup}>
            <PopupWrapper visible={visible} onClick={e => e.stopPropagation()}>
                <Row>
                    <Title>Song Details</Title>
                    <ClosePopup closePopup={closePopup} />
                </Row>
                <Content
                    selectedSong={selectedSong}
                    closePopup={closePopup}
                />
            </PopupWrapper>
        </PopupWrapperOverlay>

    )
}
const SongDetailsModal = ({ isApproved, popupVisible, type, value, showModal, closePopup, selectedSong, setPopupVisibility }) => {

    return (
        <>
            <Wrapper isApproved={isApproved} selectedSong={selectedSong}>
            </Wrapper>
            <Popup
                selectedSong={selectedSong}
                setPopupVisibility={setPopupVisibility}
                closePopup={closePopup}
                visible={popupVisible !== undefined ? popupVisible : false}
                type={type}
                value={value} />
        </>
    )
}

export default SongDetailsModal
