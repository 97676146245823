import styled from "styled-components";

export const Wrapper = styled.div`
width: 100%;
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: start;
padding: 60px 0 ;
background: ${props => props.background};

    h1{
        text-transform: uppercase;
        font-size: 1.1em;
        font-weight: 400;
    }

`;

export const Content = styled.div`
max-width: 800px;
display: grid;
position: relative;
grid-column-gap: 10%;
margin-top: 60px;
grid-template-columns: repeat(3, 1fr);

`;
