import SongwritersImg1 from "./images/songwriter_benefits_1.svg";
import SongwritersImg2 from "./images/songwriter_benefits_2.svg";
import SongwritersImg3 from "./images/songwriter_benefits_3.svg";

import BuyerImg1 from "./images/buyer_benefits_1.svg";
import BuyerImg2 from "./images/buyer_benefits_2.svg";
import BuyerImg3 from "./images/buyer_benefits_3.svg";


export default {
    "Songwriters" : [
    {
        "imgUrl": SongwritersImg1,
        "description": "Earn money from your unreleased songs"
    },
    {
        "imgUrl": SongwritersImg2,
        "description": <>{'Secure placements with established industry buyers'}</>
    },
    {
        "imgUrl": SongwritersImg3,
        "description": <>{'Own 100%'} <br /> {'of your royalties'}</>
    }
    ],
    "Buyers" : [
        {
            "imgUrl": BuyerImg1,
            "description": "Discover your artist's next great song"
        },
        {
            "imgUrl": BuyerImg2,
            "description": <>{'Obtain exclusive'} <br /> {'release rights'}</>
        },
        {
            "imgUrl": BuyerImg3,
            "description": "Gain private access to superior quality songs"
        }
        ]

}