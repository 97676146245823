import styled from "styled-components";

export const Wrapper = styled.div`
    /* background: var(--greyBackground); */
    background: ${props => props.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    h1{
        margin-top: 60px;
        font-size: var(--fontBig);
        font-weight: 300;
    }
`;

export const Content = styled.div`
/* max-width: 1193px; */
margin: 0 60px;
display: flex;
justify-content: space-between;
flex-direction: column;

@media screen and (min-width: 720px){
            flex-direction: row;
        }

img{
    width: 67%;
    height: auto;
    object-fit: contain;
    max-width: 1100px;
}
`;

export const InnerContent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

button{
    margin-top: 60px;
}
/* button{
    background: transparent;
    color: white;
    width: 198px;
    font-size: var(--smallButtonText);
    height: var(--buttonHeight);
    border: 1px solid rgba(255,255,255,0.25);
} */
`;

export const Text = styled.div`
font-size: var(--fontBig);
font-weight: 300;

strong{
    font-weight: 700;
}
`;