import styled from "styled-components";

export const LoadingWheelWrapper = styled.div`
    border: ${props=> props.border ? props.border : '2px solid var(--greyBackground)'};
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
`;