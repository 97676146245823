import React, { useRef, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import {
    Wrapper,
    ArtistName,
    Instagram,
    Spotify,
    SongTitle,
    Tempo,
    Genre,
    Date,
    AcceptButtonContainer,
    RejectButtonContainer,
    PlayButtonContainer,
    Email,
    GridItem,
    Status

} from './Songwriter.styles'

import Accept from '../../../images/acceptIcon.svg'
import Reject from '../../../images/rejectIcon.svg'
import Play from '../../../images/play.svg'
import Pause from '../../../images/pause.svg'
import SpotifyIcon from '../../../images/spotify.svg'
import InstagramIcon from '../../../images/instagramIcon.svg'

import { timeElapsed, calculatePriceWithFee } from '../../../helpers'

import AcceptAndReject from '../AcceptAndReject'
import SongDetailsModal from '../SongDetailsModal'



const AcceptButton = () => (
    <AcceptButtonContainer>
        <img src={Accept} alt="accept-button" />
    </AcceptButtonContainer>
)

const RejectButton = () => (
    <RejectButtonContainer>
        <img src={Reject} alt="reject-button" />
    </RejectButtonContainer>
)

const PlayButton = ({ onClick, playing, isCurrent }) => {
    if (playing && isCurrent) {
        return (
            <PlayButtonContainer onClick={onClick}>
                <img src={Pause} alt="play-button" />
            </PlayButtonContainer>
        )
    }
    else {
        return (
            <PlayButtonContainer onClick={onClick}>
                <img src={Play} alt="play-button" />
            </PlayButtonContainer>
        )
    }

}


const SpotifyLink = ({ spotify }) => {
    const spotifyLink = spotify === null ? spotify : spotify.includes('https://') ? spotify : 'https://' + spotify
    return (
        <Spotify
            href={spotifyLink != '' ? spotifyLink : undefined}
            target="_blank"
            spotify={spotify}>
            <img src={SpotifyIcon} alt="spotifyIcon" />
        </Spotify>
    )
}

const InstagramLink = ({ instagram }) => {
    const instagramLink = instagram === null ? '' : `https://www.instagram.com/${instagram.split('@').length > 1 ? instagram.split('@')[1] : instagram.split('@')[0]}`;
    return (
        <Instagram
            href={instagramLink != '' ? instagramLink : undefined}
            target="_blank"
            instagram={instagram}>
            <img src={InstagramIcon} alt="spotifyIcon" />
        </Instagram>
    )
}

const Songwriter = ({ onSkip, handleSongAssignment, songStatusKey, type, handleArtistAssignment, setProcessArtist, values, playing, handleSongChange, nowPlaying, isApproved }) => {

    const [popupVisible, setPopupVisibility] = useState(false);
    const [action, setAction] = useState('');
    const handleAssignment = (input) => (event) => {
        setPopupVisibility(true)
        setAction(input)

        const artist = {
            userID: values.user_id,
            songID: values.song_id,
            status: input === "accept" ? '1' : '0',
            email: values.email,
            name: values.name,
            songTitle: values.song_title
        }
        //console.log("artist", artist)
        setProcessArtist(artist)
    }

    const closePopup = () => {
        setPopupVisibility(false);
        setAction('')
    }
    const tempo = values.bpm ? values.bpm.split(' ')[0] : ''
    const genres = values.genre ? eval(values.genre) : []
    dayjs.extend(RelativeTime)
    var updateLocale = require('dayjs/plugin/updateLocale')
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s",
            s: 'now',
            m: "1m",
            mm: "%d m",
            h: "1hr",
            hh: "%d hrs",
            d: "a day",
            dd: "%d d",
            M: "1M",
            MM: "%d Ms",
            y: "a year",
            yy: "%d years"
        }
    })
    const date = dayjs.unix(parseInt(values.song_status) === 1 ? values.created_at : values.updated_at).fromNow();

    if (isApproved && type !== "songs") {
        return (
            <Wrapper
                action={action}
                isApproved={isApproved}
                gridTemplateAreas={"artist email publisher instagram spotify acceptreject"}
                gridTemplateCols={'1fr 2fr 1fr 80px 60px 110px'}
            >
                <Email>{values.email}</Email>
                <GridItem gridArea="publisher">{values.publisher || '-'}</GridItem>
                <InstagramLink instagram={values.instagram}>{values.instagram}</InstagramLink>
                <ArtistName>{values.name}</ArtistName>
                <SpotifyLink spotify={values.spotify} />
                <AcceptAndReject
                    handleArtistAssignment={handleArtistAssignment}
                    popupVisible={popupVisible}
                    action={action}
                    handleAssignment={handleAssignment}
                    closePopup={closePopup}
                    userID={values.user_id}
                    songID={values.song_id}
                    isApproved={isApproved}
                    type={isApproved !== undefined && isApproved && type === "songs" ? "song" : "artist"}
                    value={isApproved !== undefined && isApproved && type === "songs" ? values.song_title : values.name} />
            </Wrapper>
        )
    }
    else if (!isApproved && type !== "songs") {
        return (
            <Wrapper
                action={action}
                isCurrent={nowPlaying === values.song_id ? true : false}
                gridTemplateAreas={"play artist song tempo genre publisher date  instagram spotify acceptreject"}
                gridTemplateCols={'min-content 2fr repeat(2,1fr) 120px 1fr 70px 80px 60px 110px'}
            >
                <PlayButton onClick={() => handleSongChange(`${values.file_path}`, `${values.song_id}`)} playing={playing} isCurrent={nowPlaying === values.song_id ? true : false} />
                <SongTitle>{values.song_title}</SongTitle>
                <Tempo>{tempo}</Tempo>
                <GridItem gridArea="publisher">{values.publisher || '-'}</GridItem>
                <Genre>
                    {genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}
                </Genre>
                <ArtistName>{values.email}</ArtistName>
                <InstagramLink instagram={values.instagram} />
                <SpotifyLink spotify={values.spotify} />
                <Date>{date}</Date>
                <AcceptAndReject
                    handleArtistAssignment={handleArtistAssignment}
                    popupVisible={popupVisible}
                    action={action}
                    handleAssignment={handleAssignment}
                    closePopup={closePopup}
                    isApproved={isApproved}
                    type={isApproved !== undefined && isApproved ? "song" : "artist"}
                    value={isApproved !== undefined && isApproved ? values.song_title : values.name} />

            </Wrapper>
        )
    }
    else if (isApproved && type === "songs") {
        const song_state = songStatusKey.filter(st => st.id === values.song_status)[0].status;

        return (
            <>
                <Wrapper

                    showSongStatus={values.song_status !== 1}
                    action={action}
                    isCurrent={nowPlaying === values.song_id ? true : false} //min-content repeat(4,1fr) 12rem 5.5rem 60px 60px
                    gridTemplateAreas={values.song_status !== 1 ? "play song artist tempo genre status price date spotify" : "play song artist tempo genre date spotify"}
                    // gridTemplateCols={values.song_status !== 1 ? "min-content repeat(6,1fr) 60px 60px" : "min-content repeat(5,1fr) 60px"}
                    gridTemplateCols={values.song_status !== 1 ? "min-content repeat(4,1fr) 250px 100px 60px 60px" : "min-content repeat(5,1fr) 60px"}
                >
                    <PlayButton onClick={() => handleSongChange(`${values.file_path}`, `${values.song_id}`)} playing={playing} isCurrent={nowPlaying === values.song_id ? true : false} />
                    <SongTitle>{values.song_title}</SongTitle>
                    <Tempo>{tempo}</Tempo>
                    <Genre>
                        {genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}
                    </Genre>
                    <ArtistName>{values.name}</ArtistName>
                    <SpotifyLink spotify={values.spotify} />
                    <GridItem gridArea="price">{values.price !== null && values.price !== undefined ? `${calculatePriceWithFee(values.price).total}` : "-"}</GridItem>
                    <Date>{date}</Date>
                    {values.song_status !== 1 && <Status functionalStatus={values.song_status >= 3 || (parseInt(values.song_status) === 2 && values.contributors !== undefined)} onClick={values.song_status >= 3 || (parseInt(values.song_status) === 2 && values.contributors !== undefined) ? () => setPopupVisibility(true) : undefined}>{song_state}</Status>}

                </Wrapper>
                {(values.song_status >= 3 || (parseInt(values.song_status) === 2 && values.contributors !== undefined)) && <SongDetailsModal selectedSong={values} closePopup={closePopup} popupVisible={popupVisible} />}
            </>
        )
    }
    else if (!isApproved && type === "songs") {
        return (
            <Wrapper
                action={action}
                isCurrent={nowPlaying === values.song_id ? true : false}
                gridTemplateAreas={"play artist song tempo genre date spotify acceptreject"}
                gridTemplateCols={'min-content repeat(5,1fr) 60px 110px'}
            >
                <PlayButton onClick={() => handleSongChange(`${values.file_path}`, `${values.song_id}`)} playing={playing} isCurrent={nowPlaying === values.song_id ? true : false} />
                <SongTitle>{values.song_title}</SongTitle>
                <Tempo>{tempo}</Tempo>
                <Genre>
                    {genres.map((genre, idx) => (idx > 0 ? ", " + genre : genre))}
                </Genre>
                <ArtistName>{values.name}</ArtistName>
                <SpotifyLink spotify={values.spotify} />
                <Date>{date}</Date>
                <AcceptAndReject

                    handleArtistAssignment={handleSongAssignment}
                    popupVisible={popupVisible}
                    action={action}
                    handleAssignment={handleAssignment}
                    closePopup={closePopup}
                    isApproved={isApproved}
                    type={"song"}
                    value={values.song_title} />

            </Wrapper>
        )
    }
    else {
        return <Wrapper></Wrapper>
    }

}

export default Songwriter
