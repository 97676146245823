import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: relative;
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};

`;


export const InputStyled = styled.input`
    height: 49px;
    padding: ${props => props.name === 'price' ? '0 20px 0 40px' : '0 20px 0 20px'};
    font-weight: 600;
    text-transform: ${props => props.name !== 'message' ? 'uppercase' : 'undefined'};
    color: white;
    width: 100%;
    background: var(--greyBackground);
    border: none;
    position: relative;
    letter-spacing: .1rem;
    box-shadow: inset 0 0 5px 1px rgb(0, 0, 0);

    ::placeholder{
        color: #696980;
    }

    :focus{
        outline: 1px solid white;
        box-shadow: inset 0 0 5px rgba(255, 255, 255, .5);
        
    }

`;

export const Wrapper = styled.div`
    position: relative;
`;

export const InnerWrapper = styled.div`
  position: relative;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 1;
`;