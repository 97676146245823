import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
text-align: center;

`;

export const Image = styled.img`
width: auto;
height: 68px;
`;

export const Text = styled.div``;