import styled from "styled-components";

export const Wrapper = styled.div`
position: relative;
top: 60px;
`;

export const CloseButtonWrapper = styled.div`
display: flex;
position: absolute;
user-select: none;
font-size: 10px;
text-transform: uppercase;
background: var(--greyBackground);
font-weight: 600;
letter-spacing: 0.03rem;
padding: 10px 70px;
width: 100%;

align-items: center;
img{
    height: 12px;
    transform: rotate(180deg);
    margin-right: 5px;
}
`;

export const CloseButtonWrapperText = styled.div`
display: flex;
align-items: center;
`;

export const WrapperOverlay = styled.div`
background: #000000;
width: 100%;
height: 100%;
z-index: 2;
position: absolute;
display: flex;
flex-direction: column;
top: 0;
`;