import React from 'react'

// Components
import HomeBenefitsSection from './HomeBenefitsSection';
import HomeBenefitsSubsection from './HomeBenefitsSubsection';
import EmailSubscriptionSection from './EmailSubscriptionSection';
import HomeIntro from './HomeIntro';


import benefitsSectionContent from '../benefitsSection.js'

const fs = require('fs');
const util = require('util');


const Home = () => {

    const SongwritersSectionContent = benefitsSectionContent['Songwriters'];
    const BuyersSectionContent = benefitsSectionContent['Buyers'];

    //console.log(benefitsSectionContent)




    return (
        <>
            <HomeIntro background='var(--greyBackground)' />
            <HomeBenefitsSection title='Songwriters' >
                {
                    SongwritersSectionContent.map((section, index) => (

                        <HomeBenefitsSubsection
                            key={index}
                            text={section['description']}
                            img={section['imgUrl']}
                        />

                    ))
                }
            </HomeBenefitsSection>
            <HomeBenefitsSection background='var(--greyBackground)' title="A&R's" >
                {
                    BuyersSectionContent.map((section, index) => (

                        <HomeBenefitsSubsection
                            key={index}
                            text={section['description']}
                            img={section['imgUrl']}
                        />

                    ))

                }
            </HomeBenefitsSection>
            <EmailSubscriptionSection></EmailSubscriptionSection>
        </>
    )
}

export default Home;