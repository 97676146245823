export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const returnMonth = (date, abbreviated = false) => {
    const month = date.split('/')[0]
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthsAbbreviated = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month_index = month - 1;
    return abbreviated ? monthsAbbreviated[month_index] : months[month_index];
}

export const timeElapsed = (date) => {
    const dateReceived = new Date(date).getTime(); //new Date(date).getTime();
    const now = new Date();
    const dateFormatted = new Date(date).toLocaleString().split(' ')[0]
    const monthReceived = new Date(date).getMonth(); //now.getMonth() - 
    const monthsElapsed = Math.abs(now.getMonth() - new Date(dateReceived).getMonth());

    const nowTime = now.getTime();
    const delta = nowTime - dateReceived;
    const days = Math.floor(delta / (1000 * 60 * 60 * 24))
    const hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((delta % (1000 * 60)) / 1000);

    const str = monthsElapsed > 0 ? `${returnMonth(dateFormatted, true) + ' ' + dateFormatted.split('/')[1]}` : days > 0 ? `${days}d` : hours > 0 ? `${hours}h` : minutes > 0 ? `${minutes}m` : 'now';

    const result = delta < 0 ? "now" : str !== "now" && monthsElapsed <= 0 ? str : str;



    return result

}

export const timeElapsedFromCreatedAt = (createdAt) => {
    const date = createdAt.split(' ')[0]
    const dateMMDDYYYY = date.split('-')[1] + '/' + date.split('-')[2] + '/' + date.split('-')[0] + ' ' + createdAt.split(' ')[1];
    const result = timeElapsed(dateMMDDYYYY);
    return result
}
export const calculateCut = (my_split = null, total_price, showTrailing = false) => {
    const myCut = total_price !== undefined && total_price !== null && total_price !== '-' && my_split !== '-' ? (parseFloat(total_price.replace(/,/g, '')) * my_split / 100.00).toString().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    return adjustPrice(myCut, showTrailing)
}
export const calculatePriceWithFee = (total_price, showTrailing = false) => {
    const myCut = total_price !== undefined && total_price !== '-' && total_price !== null ?
        {
            fee: (parseFloat(total_price.replace(/,/g, '')) * (15) / 100.00).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            total: (parseFloat(total_price.replace(/,/g, '')) * (100 + 15) / 100.00).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        : { fee: '-', total: '-' };
        return {fee: adjustPrice(myCut.fee, showTrailing), total: adjustPrice(myCut.total, showTrailing)}
}

export const adjustPrice = (price, showTrailing = false) => {
    const priceAdj = price !== undefined && price !== null ?
        price.split('.').length > 1 ?
            price.split('.')[0] + `${price.split('.')[1] !== '00' ? '.' + (price.split('.')[1] + '0').slice(0, 2) : showTrailing ? '.00' : ''}` :
            showTrailing ? price + '.00' : 
            price : '-';
    return priceAdj !== '-' ? '$' + priceAdj : priceAdj
}

export const toTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
            return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
}